(function() {

    const ESC_KEYCODE = 27;
    const BODY = document.querySelector('body');
    const OVERLAY_CALL = document.querySelector('.overlay--call');
    const CALL_REQUEST_BTN = document.querySelector('.contact__btn');
    const CALL_REQUEST = document.querySelector('.call-request');

    function showCallRequest() {
        BODY.classList.add('no-scroll');
        CALL_REQUEST.classList.add('call-request--open');
        OVERLAY_CALL.classList.add('overlay--open');
        OVERLAY_CALL.addEventListener('click', closeCallRequest);
    }

    function closeCallRequest() {
        BODY.classList.remove('no-scroll');
        CALL_REQUEST.classList.remove('call-request--open');
        OVERLAY_CALL.classList.remove('overlay--open');
        OVERLAY_CALL.removeEventListener('click', closeCallRequest);
    }

    function onEscPress (e) {
        if(e.keyCode === ESC_KEYCODE) {
            closeCallRequest();
        }
    }

    CALL_REQUEST_BTN.addEventListener('click', showCallRequest);
    document.addEventListener('keydown', onEscPress);

    window.callRequest = {
        'closeCallRequest': closeCallRequest
    }

}());
