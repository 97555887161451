(function() {

    const UPLOAD_FIELDS = document.querySelectorAll('.upload__input');

    UPLOAD_FIELDS.forEach(el => {
        el.addEventListener('change', e => {
            // remove C:\fakepath\ before file name
            // e.target.previousElementSibling.textContent = e.target.value.slice(12);
            
            e.target.previousElementSibling.textContent = e.target.files[0].name;
        })
    });

}());
