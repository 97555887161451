(function() {

    const ESC_KEYCODE = 27;
    const BODY = document.querySelector('body');
    const OVERLAY_FORM = document.querySelector('.overlay--form');
    const SEND_MSG_SUCCESS = document.querySelector('.send-msg-success');
    const SEND_MSG_FAILURE = document.querySelector('.send-msg-failure');

    function showSuccessMsg() {
        BODY.classList.add('no-scroll');
        const SEND_MSG_CLOSE = SEND_MSG_SUCCESS.querySelector('.send-msg__close');
        SEND_MSG_SUCCESS.classList.add('send-msg--open');
        OVERLAY_FORM.classList.add('overlay--open');
        SEND_MSG_CLOSE.addEventListener('click', closeSendMsg);
        OVERLAY_FORM.addEventListener('click', closeSendMsg);
    }

    function showFailureMsg() {
        BODY.classList.add('no-scroll');
        const SEND_MSG_CLOSE = SEND_MSG_FAILURE.querySelector('.send-msg__close');
        SEND_MSG_FAILURE.classList.add('send-msg--open');
        OVERLAY_FORM.classList.add('overlay--open');
        SEND_MSG_CLOSE.addEventListener('click', closeSendMsg);
        OVERLAY_FORM.addEventListener('click', closeSendMsg);
    }

    function closeSendMsg() {
        BODY.classList.remove('no-scroll');
        const SEND_MSG_CURRENT = document.querySelector('.send-msg--open');
        SEND_MSG_CURRENT.classList.remove('send-msg--open');
        OVERLAY_FORM.classList.remove('overlay--open');
        OVERLAY_FORM.removeEventListener('click', closeSendMsg);
    }

    function onEscPress (e) {
        if(e.keyCode === ESC_KEYCODE) {
            closeSendMsg();
        }
    }

    document.addEventListener('keydown', onEscPress);

    window.sendMsg = {
        'showSuccessMsg': showSuccessMsg,
        'showFailureMsg': showFailureMsg
    }

}());
