// Слайдер секции отзывов
$(document).ready(function(){
    $('.reviews__slider').slick({
        centerPadding: 0,
        variableWidth: true,
        centerMode: true,
        draggable: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        prevArrow: $('.reviews__slider-btn--left'),
        nextArrow: $('.reviews__slider-btn--right')
    });
});

// Слайдер секции Кратко о нас
$(document).ready(function(){
    $('.about__slider').slick({
        centerPadding: 0,
        variableWidth: true,
        centerMode: true,
        draggable: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        prevArrow: $('.about__slider-btn--left'),
        nextArrow: $('.about__slider-btn--right')
    });
});

// Слайдер секции Производство
$(document).ready(function(){
    $('.manufacture__slider').slick({
        centerPadding: 0,
        variableWidth: true,
        centerMode: false,
        draggable: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        prevArrow: $('.manufacture__slider-btn--left'),
        nextArrow: $('.manufacture__slider-btn--right')
    });
});

// Слайдер секции партнеров
$(document).ready(function(){
    $('.partners__slider').slick({
        centerPadding: 0,
        // variableWidth: true,
        centerMode: false,
        draggable: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
        prevArrow: $('.partners__slider-btn--left'),
        nextArrow: $('.partners__slider-btn--right'),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true
                }
            }
        ]
    });
});


// Модальное окно производство

const ESC_KEYCODE = 27;
const pageBody = document.querySelector('body');
const manufactureSlider = document.querySelector('.manufacture__slider');
const modalManufacture = document.querySelector('.modal-manufacture');
const modalBtnClose = document.querySelector('.modal-manufacture__btn--close');
let dragging, x, y, clickedSlide;

const checkMoveMouse = (evt) => {
    if (Math.abs(x - evt.screenX) > 5 || Math.abs(y - evt.screenY) > 5) {
        dragging = true;
      }
};

const hideModalManufactore = () => {
    pageBody.classList.remove('scroll-hidden');
    modalManufacture.classList.add('visually-hidden');
    manufactureSlider.addEventListener('mousedown', onSlideMousedown);
    modalManufacture.removeEventListener('click', hideModalManufactore);
    modalBtnClose.removeEventListener('click', hideModalManufactore);
};

const onModalEscKeyDown = (evt) => {
    if (evt.keyCode === ESC_KEYCODE) {
        hideModalManufactore();
    }
    window.removeEventListener('keydown', onModalEscKeyDown);
};

const showModalManufactore = () => {
    manufactureSlider.removeEventListener("mousemove", checkMoveMouse);
    if (!dragging) {
        const modalImg = document.querySelector('.modal-manufacture__img');
        modalImg.src = clickedSlide.dataset.popupSrc;
        pageBody.classList.add('scroll-hidden');
        modalManufacture.classList.remove('visually-hidden');
        modalManufacture.addEventListener('click', hideModalManufactore);
        window.addEventListener('keydown', onModalEscKeyDown);
        modalBtnClose.addEventListener('click', hideModalManufactore);
        manufactureSlider.removeEventListener('mousedown', onSlideMousedown);
    }
};

const onSlideMousedown = (evt) => {
    clickedSlide = evt.target;
    if (clickedSlide.tagName.toLowerCase() === 'img') {
        x = evt.screenX;
        y = evt.screenY;
        dragging = false;
        manufactureSlider.addEventListener("mousemove", checkMoveMouse);
    }
};

if(manufactureSlider) {
    manufactureSlider.addEventListener('mousedown', onSlideMousedown, true);
    manufactureSlider.addEventListener("mouseup", showModalManufactore);
}

setTimeout(function(){
    $('.materials').addClass('materials--load');    
}, 8000);

document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});