(function() {

    const BODY = document.querySelector('body');
    const CONTACT = document.querySelector('.contact');
    const CONTACT_TOGGLE = CONTACT.querySelector('.contact__toggle');
    const MENU = document.querySelector('.menu');
    const MENU_TOGGLE = MENU.querySelector('.menu__toggle');
    const MENU_LINKS = MENU.querySelectorAll('.menu__link');

    function toggleElText(el) {
        return el.textContent = el.textContent === 'Закрыть' ? 'Открыть' : 'Закрыть';
    }

    CONTACT_TOGGLE.addEventListener('click', () => {
        BODY.classList.toggle('no-scroll');
        CONTACT.classList.toggle('header__contact--open');
        CONTACT_TOGGLE.classList.toggle('toggle--block-open');
        toggleElText(CONTACT_TOGGLE);
    });

    MENU_TOGGLE.addEventListener('click', () => {
        BODY.classList.toggle('no-scroll');
        MENU.classList.toggle('header__menu--open');
        MENU_TOGGLE.classList.toggle('toggle--block-open');
        toggleElText(MENU_TOGGLE);
    });

    MENU_LINKS.forEach(el => el.addEventListener('click', (e) => {
        BODY.classList.remove('no-scroll');
        MENU.classList.remove('header__menu--open');
        MENU_TOGGLE.classList.remove('toggle--block-open');
        toggleElText(MENU_TOGGLE);
    }));
}());

$('.contact__toggle').click(function (e) { 
    e.preventDefault();
    
    if ($('.contact__toggle').hasClass('toggle--block-open')) {
    } else {
        $('body').removeClass('no-scroll');
    }
});