// Записываем токен рекапчи в сессионную память

// setTimeout(function(){
//     var s = document.createElement("script");
//     let rec = $('#lazy-recap');
//     s.type = "text/javascript";
//     s.src = "https://www.google.com/recaptcha/api.js?render=6LcR62UaAAAAALxnCnMQbKdeA5UCzLPrISwOIF-T";
//     // Use any selector
//     rec.append(s);
// }, 5000);
    
// setTimeout(function(){
//     grecaptcha.ready(function() {
//         grecaptcha.execute('6LcR62UaAAAAALxnCnMQbKdeA5UCzLPrISwOIF-T', {action: 'homepage'}).then(function(token) {
//             sessionStorage.setItem('token', token);
//         });
//     });
    
// }, 8000);
    

(function() {
    const URL = '/ajax-handler/';
    
    const FORMS = document.querySelectorAll('.js-form');
    const FORM_FIELDS = document.querySelectorAll('.js-field');
    const SUBMITS = document.querySelectorAll('.js-submit');
    const TELS = document.querySelectorAll('.js-tel');

    TELS.forEach(el => {
        Inputmask({
            regex: '\\+7\\([0-9]{3}\\)-[0-9]{3}-[0-9]{4}'
        })
            .mask(el);
    });

    const OPTIONS = {
        uiEnabled: false,
        focus: "none"
    };

    // Обновление сообщений валидации при правке полей
    FORM_FIELDS.forEach(el => {
        el.addEventListener('focus', function (e) {
            e.target.parentElement.classList.remove('form__field-wrapper--error');
            e.target.parentElement.classList.remove('form__field-wrapper--success');
        })
    });

    FORMS.forEach(el => {
        $(el).parsley(OPTIONS);

        // Поле - обработка ошибки
        $(el).parsley().on('field:error', function () {
            this.$element.val('');
            this.$element.closest('.form__field-wrapper').addClass('form__field-wrapper--error');
        });

        // Поле - обработка успеха
        $(el).parsley().on('field:success', function () {
            this.$element.closest('.form__field-wrapper').addClass('form__field-wrapper--success');
        });

        // Форма - отправка данных
        $(el).parsley().on('form:success', function () {
            let formEl = this.$element.get(0);
            
            // Получаем токен рекапчи из сессионной памяти 
            var token = sessionStorage.getItem('token');
            this.$element.find('.js-recap').attr('name', 'g-recaptcha-response').attr('id', 'g-recaptcha-response').attr('value', token);

            let data = new FormData(formEl);
            let options = {
                method: 'POST',
                body: data,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
            };

            let req = new Request(URL, options);

            // Сброс значений полей и стилей
            function resetFormCustom () {
                formEl.reset();

                if (formEl.querySelector('.upload__path')) {
                    formEl.querySelector('.upload__path').textContent = 'Выберите файл';
                }

                let fieldWrappers = formEl.querySelectorAll('.form__field-wrapper');
                fieldWrappers.forEach(el => {
                    el.classList.remove('form__field-wrapper--success')
                });

                // Создать новый токен для рекапчи
                // $('.js-recap').attr('id', '').attr('name', '').attr('value', '');
                // grecaptcha.execute('6LcR62UaAAAAALxnCnMQbKdeA5UCzLPrISwOIF-T', {action: 'homepage'}).then(function(token) {
                //     sessionStorage.setItem('token', token);
                // });
            }

            fetch(req)
                .then(res => {
                    if(res.ok) {
                        resetFormCustom();
                        if (document.querySelector('.call-request--open')) {
                            window.callRequest.closeCallRequest();
                        }
                        // window.sendMsg.showSuccessMsg();
                        return res.json();
                    } else {
                        resetFormCustom();
                        if (document.querySelector('.call-request--open')) {
                            window.callRequest.closeCallRequest();
                        }
                        window.sendMsg.showFailureMsg();
                    }
                })
                .then(json => {
                    if (json.isSent) {
                        window.sendMsg.showSuccessMsg();
                        if (yandex_metrika_id) {
                            ym(yandex_metrika_id, 'reachGoal', 'form_send_success');
                        }
                    } else {
                        window.sendMsg.showFailureMsg();
                    }
                })
                .catch(() => {
                    resetFormCustom();
                    if (document.querySelector('.call-request--open')) {
                        window.callRequest.closeCallRequest();
                    }
                    window.sendMsg.showFailureMsg();
                });

        });

    });

    function submitButtonListener(e) {
        e.preventDefault();
        const FORM = e.target.closest('.js-form');
        $(FORM).parsley().validate();
    }

    SUBMITS.forEach(el => {
        el.addEventListener('click', submitButtonListener)
    })

}());
