$.extend(true, $.magnificPopup.defaults, {
	tClose: 'Закрыть (Esc)', // Alt text on close button
	tLoading: 'Загрузка...', // Text that is displayed during loading. Can contain %curr% and %total% keys
	gallery: {
		tPrev: 'Назад (Стрелка влево)', // Alt text on left arrow
		tNext: 'Вперёд (Стрелка вправо)', // Alt text on right arrow
		tCounter: '%curr% из %total%' // Markup for "1 of 7" counter
	},
	image: {
		tError: 'Не удалось загрузить <a href="%url%">изображение</a>.' // Error message when image could not be loaded
	},
	ajax: {
		tError: 'Не удалось загрузить <a href="%url%">содержимое</a>.' // Error message when ajax request failed
	}
});

// Галереи для каталога и примеров работ
$(document).ready(function() {
    $('.js-mfp-ajax').click(function(event) {
    
        event.preventDefault()
        
        var items = [];
        var itemsDataFilePath = $(this).attr('href');

        $.getJSON(itemsDataFilePath, function(data) {

            $.each(data, function(key, val) {                
                items.push(
                    {
                        "src": val.src,
                        "title": val.title,
                    }
                );
            });

            $.magnificPopup.open({
                items: items,
                gallery: {
                    enabled: true 
                },
                type: 'image'
            });
        })
    });

    $('.js-mfp-gallery').each(function() {
        $(this).magnificPopup({
            delegate: '.js-mfp-gallery-item',
            gallery: {
              enabled: true
            },
            type: 'image'
        });
    });  
});
